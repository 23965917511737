import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import featureImage from "../../static/images/story-1.png"
import thumbnailEvent from "../../static/images/story-2.png"
import thumbnailStep from "../../static/images/step.svg"
import thumbnailBoard from "../../static/images/story-3.png"
import map from "../../static/images/map.png"
import thumbnailNews from "../../static/images/groupe7.webp"
import pcard from "../../static/images/card.png"
import thumbnailTeams from "../../static/images/feature-team.png"
import thumbnailStaff from "../../static/images/feature-user.png"
/* import News3CardDemo from "../components/cardUI" */
import CookieConsent from "react-cookie-consent";

import loadable from '@loadable/component'

const News3CardDemo = loadable(() => import("../components/cardUI"))



const IndexPage = () => (


    <Layout>
        <CookieConsent
            location="bottom"
            buttonText="J'accepte"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            >
            Ce site utilise des cookies pour optimiser votre expérience utilisateur, ainsi qu'à des fins publicitaires, pour permettre de suivre le comportement utilisateur, ainsi qu'afin d'activer les fonctions de partage sur les réseaux sociaux. En utilisant notre plate-forme, vous acceptez notre utilisation des cookies.{" "}
            {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
        </CookieConsent>
        <SEO title="Une sélection de photographes professionnels pour votre mariage"/>
        

        <div className={"page-header home"}>
                    
                    <div className={"row"}>
                        <div className={"col-5"}>
                            <div >
                                <h1>A la recherche d'un photographe professionnel?</h1>
                                <h2>Notre équipe de conseillers vous accompagne</h2>
                            </div>
                            <div className={"get-started"}>
                                <a href="https://infovivr.typeform.com/to/D2X2ScCq" >Demander un devis</a>
                            </div>
                        </div>
                        <div className={"col-7"}>
                                <img alt={"Dashboard"} src={featureImage}/>
                        </div>
                    </div>
        </div>


        <div className={"container"}>
            <div className={"features"}>
                <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-12"} >
                            <div className={"feature__content"}>
                                <h2>A vos cotés de la mise en relation au shooting photo</h2>
                            </div>
                        </div>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailEvent}/>
                            </div>
                        </div>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Event"} src={thumbnailStep}/>
                                {/* <div className={"link"}>
                                    <h3>Mise en relation avec <br/>le photographe</h3>
                                </div> */}
                            </div>
                        </div>

                    </div>
                </div>
                                        
                <div className={"feature__item1"}>
                    
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"row"}>
                            <div className={"feature__content"}>
                                <h2>Les avantages de VIVR pour des photos réussies</h2>
                            </div>

                            </div>
                        </div>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <div className={"row"}>
                                    <img alt={"Map"} src={map}/>
                                    <div className={"feature__content"}>
                                        <h3>Un réseau de photographes professionnels à portée de clic </h3>
                                    </div>
                                </div>    
                            </div>
                            
                        </div>

                        <div className={"col-6"}>
                            <div className={"thumbnail"}>
                                <div className={"row"}>
                                    <img alt={"Board"} src={thumbnailBoard}/>
                                    <div className={"feature__content"}>
                                        <h3>Une sélection rigoureuse de nos partenaires pour une prestation réussie </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"feature__item2"}>
                    <div className={"row"}>
                        {/* <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"News"} src={thumbnailNews}/>
                            </div>
                        </div> */}

                        <div className={"col-12 first"}>
                            <div className={"feature__content"}>
                                <h2>Nos photographes vous accompagnent <br/> de la séance d'engagement au jour-J </h2>
                            </div>
                        </div>
                        <div className={"col-12 second"}>

                            <News3CardDemo/>


                                
                            
                        </div>
                            {/* <div className={"col-3"}>
                                <div className={"card"}>
                                    <img alt={"Card"} src={pcard}/>
                                </div>
                            </div> */}


                            
                            

                            
                            
                            
                        
                    </div>
                </div>

                {/* <div className={"feature__item1"}>
                    <div className={"row"}>
                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Teams</h2>
                                <p>HiStaff let you add staff in specific teams and manage them easily.</p>
                            </div>
                        </div>

                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Team"} src={thumbnailTeams}/>
                            </div>
                        </div>
                    </div>
                </div> */}

                {/* <div className={"feature__item"}>
                    <div className={"row"}>
                        <div className={"col-6 first"}>
                            <div className={"thumbnail"}>
                                <img alt={"Users"} src={thumbnailStaff}/>
                            </div>
                        </div>

                        <div className={"col-6"}>
                            <div className={"feature__content"}>
                                <h2>Staff management</h2>
                                <p>Discard traditional ways to archive staff documents and information, and try new ways to store and archive them in HiStaff easily.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>

        {/* <div className={"call-to-action"}>
            <div className={"container"}>
                <div className={"call-to-action__content"}>
                    <h2>Sign up for free</h2>
                    <p>Sign up and start increasing the productivity of your business with HiStaff.</p>
                </div>

                <div className={"button"}>
                    <a href="https://app.histaff.io" target={"_blank"}>Get Started</a>
                </div>
            </div>
        </div> */}
    </Layout>
    )


export default IndexPage
